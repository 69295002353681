import { get, post } from "@/utils/request";

/**
 * H5领资料
 * userMobile 用户手机号
 * code 短信验证码
 * categoryName 考试类型
 * province 省份
 * @returns Object
 */
export const setActivityUser = (data) =>
  post("/book/p/user/setActivityUser", data);

/**
 * 获取勘误分类列表
 */
export const getCorrigendumCategorys = () =>
  get(`/book/category/getCorrigendumCategorys`);

/**
 * 商品勘误列表查询
 */
export const getCorrigendumById = (data) =>
  get(`/book/corrigendum/getCorrigendumById`, data);

/**
 * 根据分类查询勘误信息
 * categoryId 分类ID
 * current	当前页，默认1
 * size
 */
export const getCorrigendumByCategory = (data) =>
  get("/book/prod/getCorrigendumByCategory", data);

/**
 * 勘误搜索接口
 */
export const searchCorrigendumByName = (data) =>
  get("/book/search/searchCorrigendumByName", data);
