<template>
  <div class="search">
    <div class="search-main" :style="'background-color: ' + bgColor + ';'">
      <div class="search-main-left">
        <div class="search-main-left-text"></div>
        <div class="search-main-left-icon" v-if="isShowSuffix">
          <slot name="suffix">
            <img
              mode="aspectFit"
              src="@/assets/imgs/bookCorrect/icon-search.png"
              class="search-main-left-icon-img"
            />
          </slot>
        </div>
      </div>
      <form style="width: 100%" action="" @submit.prevent="">
        <div class="search-main-body">
          <input
            type="search"
            ref="input"
            :focus="defaultFocus"
            :placeholder="placeholder"
            placeholder-class="search-main-input-placeholder"
            :maxlength="maxlength"
            confirm-type="search"
            v-model="value"
            class="search-main-body-input"
            :disabled="disabled"
            @focus="focus"
            @input="onChange"
            @blur="onBlur"
            @keypress="searchConfirm"
          />
          <div v-show="isFocus && value">
            <div class="search-main-close" @click="onClose">
              <img
                mode="aspectFit"
                src="@/assets/imgs/bookCorrect/icon-close.png"
                class="search-main-close-img"
              />
            </div>
          </div>
        </div>
      </form>

      <div class="search-main-right">
        <slot name="prefix"></slot>
      </div>
    </div>
    <!-- NOTE 右侧actions 预留 -->
    <div class="search-right-actions">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFocus: false,
      defaultFocus: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.searchValue;
      },
      set(val) {
        this.$emit("update:searchValue", val);
      },
    },
  },
  options: {
    multipleSlots: true,
    // 在组件定义时的选项中启用多slot支持
    virtualHost: true,
  },
  externalClasses: ["class"],
  props: {
    searchValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "#FFFFFF",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isShowSuffix: {
      type: Boolean,
      default: true,
    },
    isDefaultFocus: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.autoFocus();
  },
  methods: {
    /**
     *
     */
    autoFocus() {
      // 动画执行时间3毫秒，防止冲突延迟唤醒软键盘
      if (this.isDefaultFocus) {
        setTimeout(() => {
          this.defaultFocus = true;
          this.$refs.input.focus();
          this.isFocus = true;
        }, 350);
      }
    },
    onChange(e) {
      let { data } = e;
      if (data) {
        this.isFocus = true;
      }
      // 自定义一个事件，并且传值
      this.$emit(
        "change",
        {
          detail: data,
        },
        {}
      );
    },
    /**
     * 按钮点击事件
     */
    searchConfirm(event) {
      if (event.keyCode == 13) {
        //如果按的是enter键 13是enter
        event.preventDefault(); //禁止默认事件（默认是换行）
        // document.activeElement.blur();
        this.$emit(
          "search",
          {
            detail: this.value,
          },
          {}
        );
      }
    },
    focus() {
      this.$emit("focus");
      this.isFocus = true;
    },
    onBlur() {
      setTimeout(() => {
        this.isFocus = false;
      });
      this.$emit("onBlur");
    },

    onClose() {
      this.isFocus = false;
      this.value = "";
    },
  },
};
</script>
<style>
.search {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.search-main {
  display: flex;
  align-items: center;
  border-radius: 100px;
  flex: 1;
  padding: 0 32px;
  height: 64px;
}
.search-main-left {
  display: flex;
  align-items: center;
}

.search-main-left-icon {
  display: flex;
  align-items: center;
}
.search-main-left-icon-img {
  width: 40px;
  height: 40px;
}

.search-main-body {
  display: flex;
  align-items: center;
  flex: 1;
}

.search-main-body-input {
  color: #333;
  flex: 1;
  font-size: 32px;
  height: 64px;
  background-color: transparent;
  border-width: 0px;
  box-sizing: border-box;
  padding: 12px 12px;
  align-items: center;
  line-height: 40px\9;
}

.search-main-input-placeholder {
  color: #999;
  height: 30px;
}
.search-main-close {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-main-close-img {
  width: 30px;
  height: 30px;
}

.search-main-right {
  display: flex;
}

.search-right-actions {
  display: flex;
  color: #333;
  font-size: 32px;
}
input[type="search"] {
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-decoration {
  display: none;
}
input::-webkit-search-cancel-button {
  display: none;
}
</style>
