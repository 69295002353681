<template>
  <div class="empty">
    <img
      :src="img"
      :style="'width:' + width + ';height:' + height + ';'"
      class="empty-img"
    />
    <div class="empty-title">{{ title }}</div>
    <div class="empty-bottom-title" v-if="isBookCorrectEmpty">
      若您对库课出版的图书有任何建议与想法，欢迎通过<span class="keywordTitle"
        >【图书封底的微信答疑群】</span
      >与我们交流哦
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: "https://kuke-utils.kuke99.com/common/img/book/no-basket.png",
    },
    width: {
      type: String,
      default: "210px",
    },
    height: {
      type: String,
      default: "145px",
    },
    title: {
      type: String,
      default: "",
    },
    isBookCorrectEmpty: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty-img {
  margin-bottom: 16px;
}
.empty-title {
  font-size: 28px;
  color: #999999;
  line-height: 40px;
  margin-bottom: 30px;
}
.empty-bottom-title {
  position: absolute;
  padding-left: 28px;
  padding-right: 28px;
  height: 72px;
  font-size: 24px;
  color: #999999;
  line-height: 36px;
  letter-spacing: 1px;
  bottom: 81px;
}
.keywordTitle {
  font-size: 24px;
  color: #f01414;
  line-height: 36px;
  letter-spacing: 1px;
  font-weight: 500;
}
</style>
