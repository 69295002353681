<template>
  <div class="bg">
    <div class="flex-wrap" :style="flexWrapStyle">
      <div v-for="(value, index) in prodList" :key="index">
        <div class="flex-item" @click="itemClick(value)">
          <div class="flex-column">
            <img class="prodImg" :src="value.pic" mode="aspectFit" alt="" />
            <div class="text">
              {{ value.prodName }}
            </div>
            <div class="subTitle">{{ value.corrigendumNum }}个勘误</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prodList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      flexWrapStyle: {
        width: "",
      },
    };
  },
  mounted() {
    this.wrapStyle();
    //窗口尺寸改变
    window.addEventListener("resize", () => this.wrapStyle());
  },
  destroyed() {
    // 销毁
    window.removeEventListener("resize", this.wrapStyle());
  },
  methods: {
    getRemPx(width) {
      return width / 75.0;
    },
    wrapStyle() {
      let itemWidth = this.getRemPx(357);
      let screenWidth =
        document.documentElement.clientWidth || window.screen.width;
      let column = 2;
      if (screenWidth > 550) {
        let scale = 0.72;
        let width = 357 * scale;
        column = Math.floor(screenWidth / width + 0.05);
      }
      this.flexWrapStyle = {
        width: `${itemWidth * column}rem`,
      };
    },
    itemClick(value) {
      this.$router.push({
        path: "/bookCorrect/correctDetail",
        query: { prodId: value.prodId },
      });
    },
  },
};
</script>

<style>
.bg {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  align-items: center;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  height: auto;
}

.flex-item {
  width: 337px;
  height: 502px;
  background: #ffffff;
  border-radius: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
}
.flex-column {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.prodImg {
  height: 337px;
  width: 337px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  object-fit: cover;
}
.text {
  margin: 20px 20px 10px 20px;
  font-size: 26px;
  color: #333333;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -moz-box;
  -moz-line-clamp: 2;
  -moz-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
}
.subTitle {
  margin: auto 20px 30px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 24px;
  color: #f01414;
  line-height: 33px;
  text-align: left;
}
</style>
