<template>
  <div class="kkloading">
    <img src="@/assets/imgs/app/loading.png" :style="imgstyle" mode="" />
    <div :style="loadingTextStyle">{{ loadingText }}</div>
  </div>
</template>
<script>
export default {
  name: "kk-commiting",
  props: {
    loadingText: {
      //文案
      type: String,
      default: "",
    },
    loadingWidth: {
      type: Number,
      default: 41,
    },
  },
  computed: {
    imgstyle() {
      if (this.loadingWidth > 0) {
        return `width:${this.loadingWidth}px; height:${this.loadingWidth}px;`;
      }
      return `width:100%; height:100%;`;
    },
    loadingTextStyle() {
      return "margin-top: 10px;font-size: 14px;line-height: 14px;color: #999999;";
    },
  },
};
</script>

<style>
.kkloading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
