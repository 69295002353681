<template>
  <div
    class="kk-container"
    id="kk-container"
    :style="'background-color:' + backgroundColor"
    ref="kkContainer"
  >
    <div class="kk-container-header" ref="KKContaineHeader">
      <slot name="header"></slot>
    </div>
    <div
      class="kk-container-main"
      ref="KKContainerMain"
      :style="'padding-top:' + headerHeight + ';min-height:90vh;'"
    >
      <slot name="main"> </slot>
      <div
        class="kk-container-empty"
        :style="'padding-top:' + emptyTopOffset + 'px;'"
        v-if="isShowEmpty"
      >
        <empty
          :title="emptyTitle"
          :isBookCorrectEmpty="isBookCorrectEmpty"
        ></empty>
      </div>
      <div
        class="kk-container-reachBottom"
        v-if="loadMoreStatus === 'no-more'"
        :style="'padding-top:' + loadMoreMarginTop + 'px'"
      >
        {{ contentText.contentnomore }}
      </div>

      <van-loading
        class="kk-container-loadingMore"
        v-if="loadMoreStatus === 'loading'"
        :style="'padding-top:' + loadMoreMarginTop + 'px'"
        size="16px"
        textSize="11px"
        color="#999999"
        type="spinner"
        >{{ contentText.contentrefresh }}</van-loading
      >
    </div>
    <div class="kk-container-loading" v-if="isShowLoading">
      <kkloading :loadingText="loadingText"></kkloading>
    </div>
  </div>
</template>

<script>
import { Loading } from "vant";
import Empty from "@/components/KkContainer/empty";
import Kkloading from "@/components/KkContainer/Kkloading";

export default {
  components: {
    [Loading.name]: Loading,
    Empty,
    Kkloading,
  },
  props: {
    // 是否现在空页面
    isShowEmpty: {
      type: Boolean,
      default: false,
    },
    isBookCorrectEmpty: {
      type: Boolean,
      default: false,
    },
    // 空页面标题
    emptyTitle: {
      type: String,
      default: "",
    },
    // 空页面顶部偏移量
    emptyTopOffset: {
      type: Number,
      default: 0,
    },
    // 背景颜色
    backgroundColor: {
      type: String,
      default: "#f5f6f7",
    },
    // 加载更多状态 loading: 加载中 no-more: 没有更多加载完成
    loadMoreStatus: {
      type: String,
      default: "",
    },
    // 加载更多顶部偏移 单位rpx
    loadMoreMarginTop: {
      type: Number,
      default: 0,
    },
    // 是否现在loading
    isShowLoading: {
      type: Boolean,
      default: false,
    },
    // loading 文本
    loadingText: {
      type: String,
      default: "加载中",
    },
  },
  data() {
    return {
      contentText: {
        contentrefresh: "拼命加载中～",
        contentnomore: "我们也是有底线的噢～",
      },
      headerHeight: "",
      curretnScrollTop: 0,
      currentPageLocation: "",
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", `background-color:${this.backgroundColor}`);
    this.currentPageLocation = window.location.href;
    window.addEventListener("scroll", this.handleScroll, true);
    this.getHeaderHeight();
  },
  destroyed() {
    // 取消监听
    window.removeEventListener("scroll", this.handleScroll);
    // document.querySelector("body").removeAttribute("style");
  },
  methods: {
    getHeaderHeight() {
      setTimeout(() => {
        this.headerHeight = window.getComputedStyle(
          this.$refs.KKContaineHeader
        ).height;
      }, 30);
    },
    headHeight() {
      return this.headerHeight;
    },
    handleScroll() {
      if (this.currentPageLocation !== window.location.href) return;
      const el = document.getElementById("kk-container");
      const windowHeight = window.screen.height;
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.curretnScrollTop != scrollTop) {
        const contentHeight = el.clientHeight;
        this.curretnScrollTop = scrollTop;
        if (windowHeight + scrollTop + 20 >= contentHeight) {
          // 请求的数据未加载完成时
          this.$emit("scrollToBottom");
        }
      }
    },
  },
};
</script>

<style scoped>
.kk-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}
.kk-container-header {
  z-index: 100;
  width: 100%;
  position: fixed;
  background-color: #fff;
  top: 0;
}
.kk-container-main {
  z-index: 1;
  flex: 1;
  overflow: auto;
  width: 100%;
}
.kk-container-empty {
  width: 100%;
  height: 100%;
}
.kk-container-reachBottom {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  height: 33px;
  font-size: 24px;
  color: #999999;
  line-height: 33px;
}
.kk-container-loadingMore {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.kk-container-loading {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
}
</style>
