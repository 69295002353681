<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
import { getWxSign } from "@/api/test/index";
var wx = require("weixin-js-sdk");
const WX_DEBUG = false;
export default {
  data() {
    return {
      infor: {},
    };
  },
  mounted() {
    this.getSign();
  },
  methods: {
    async getSign() {
      const res = await getWxSign({
        url: encodeURIComponent(location.href.split("#")[0]),
      });
      if (res) {
        this.infor = res;
        this.initWeChat();
      }
    },
    // 注入config
    initWeChat() {
      wx.config({
        debug: WX_DEBUG,
        appId: this.infor.appId,
        nonceStr: this.infor.nonceStr, // 必填，生成签名的随机串
        signature: this.infor.signature, // 必填，签名
        timestamp: this.infor.timestamp, // 必填，生成签名的时间戳
        jsApiList: [
          "chooseImage",
          "updateTimelineShareData",
          "updateAppMessageShareData",
          "updateAppMessageShareData",
          "hideMenuItems",
        ],
        openTagList: ["wx-open-launch-weapp"], // 微信打开小程序开放标签
      });
    },
    /**
     * 预览图片
     * @param {*} current //当前显示图片的http链接
     * @param {*} urls 需要预览图片http链接列表
     */
    previewImage(current, urls) {
      wx.previewImage({
        current: current, //当前显示图片的http链接
        urls: urls, //需要预览图片http链接列表
      });
    },
  },
};
</script>
